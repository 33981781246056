var config = {
  style: 'mapbox://styles/mapbox/streets-v11',
  accessToken: 'pk.eyJ1IjoibGF5ZXJjYWtlc29sdXRpb25zIiwiYSI6ImNrMnY0NHRkZTAxNjYzaHBlaWk3NWdjczkifQ.Gq50YmNeunMBKNn5DaBSyg',
  showMarkers: true,
  theme: 'light',
  alignment: 'left',
  title: '',
  subtitle: 'A descriptive and interesting subtitle to draw in the reader',
  byline: 'By a Digital Storyteller',
  footer: 'Source: source citations, etc.',
  chapters: [
    {
      id: 'step-1',
      title: 'Charleston',
      subtitle: 'Renaissance Charleston Historic District Hotel',
      byline: '68 Wentworth St, Charleston, SC 29401',
      image: '',
      iframe: 'https://my.matterport.com/show/?m=q1K2HGpNR4p',
      description: 'Charleston, the South Carolina port city founded in 1670, is defined by its cobblestone streets, horse-drawn carriages and pastel antebellum houses, particularly in the elegant French Quarter and Battery districts. The Battery promenade and Waterfront Park both overlook Charleston Harbor, while Fort Sumter, a federal stronghold where the first shots of the Civil War rang out, lies across the water.',
      location: {
        center: [-79.93354, 32.78299],
        zoom: 15.5,
        pitch: 60,
        bearing: 10,
        speed: 1,
        curve: 1,
        easing: function (t) { return t },
      },
      onChapterEnter: [],
      onChapterExit: [],
    },
    {
      id: 'step-2',
      title: 'Hilton Head',
      subtitle: 'Marriott Grande Ocean',
      byline: '51 S Forest Beach Dr, Hilton Head Island, SC 29928',
      image: '',
      iframe: 'https://my.matterport.com/show/?m=fHFAFemXQES',
      description: 'Hilton Head Island is part of the Lowcountry region in the U.S. state of South Carolina. It\'s known for Atlantic Ocean beaches and golf courses. The Harbour Town Lighthouse and Museum marks the southwest tip. The Coastal Discovery Museum features heritage buildings, trees and themed gardens. Between the island and mainland, the Pinckney Island National Wildlife Refuge salt marsh hosts deer, alligators and birds',
      location: {
        center: [-80.76194, 32.13689],
        zoom: 15.5,
        pitch: 60,
        bearing: -43.2,
        speed: 1,
        curve: 1,
        easing: function (t) { return t },
      },
      onChapterEnter: [],
      onChapterExit: [],
    },
    {
      id: 'step-3',
      title: 'Savannah',
      subtitle: 'Marriott Savannah Riverfront',
      byline: '100 General McIntosh Blvd, Savannah, GA 31401',
      image: '',
      iframe: 'https://my.matterport.com/show/?m=uKPyg3sgFZY',
      description: 'Savannah, a coastal Georgia city, is separated from South Carolina by the Savannah River. It’s known for manicured parks, horse-drawn carriages and antebellum architecture. Its historic district is filled with cobblestoned squares and parks such as Forsyth Park shaded by oak trees covered with Spanish moss. At the center of this picturesque district is the landmark, Gothic-Revival Cathedral of Saint John the Baptist.',
      location: {
        center: [-81.08117, 32.07882],
        zoom: 15.5,
        pitch: 60,
        bearing: -43.2,
        speed: 1,
        curve: 1,
        easing: function (t) { return t },
      },
      onChapterEnter: [],
      onChapterExit: [],
    },
    {
      id: 'step-4',
      title: 'Ft Lauderdale',
      subtitle: 'Marriott Beach Place',
      byline: '17 Florida A1A, Fort Lauderdale, FL 33316',
      image: '',
      iframe: 'https://my.matterport.com/show/?m=jMCkW8W1EwD',
      description: 'Fort Lauderdale is a city on Florida\'s southeastern coast, known for its beaches and boating canals. The Strip is a promenade running along oceanside highway A1A. It\'s lined with upscale outdoor restaurants, bars, boutiques and luxury hotels. Other attractions include the International Swimming Hall of Fame, with pools and a museum of memorabilia, and Hugh Taylor Birch State Park, featuring trails and a lagoon.',
      location: {
        center: [-80.10463, 26.12215],
        zoom: 15.5,
        pitch: 60,
        bearing: -43.2,
        speed: 1,
        curve: 1,
        easing: function (t) { return t },
      },
      onChapterEnter: [],
      onChapterExit: [],
    },
    {
      id: 'step-5',
      title: 'Orlando',
      subtitle: 'Marriott\'s Sabal Palms',
      byline: '8805 World Center Dr, Orlando, FL 32821',
      image: '',
      iframe: 'https://my.matterport.com/show/?m=qF4PAFq6zwi',
      description: 'Orlando, a city in central Florida, is home to more than a dozen theme parks. Chief among its claims to fame is Walt Disney World, comprised of parks like the Magic Kingdom and Epcot, as well as water parks. Another major destination, Universal Orlando, offers Universal Studios and Islands of Adventure, with the Wizarding World of Harry Potter straddling both.',
      location: {
        center: [-81.51271, 28.36373],
        zoom: 15.5,
        pitch: 60,
        bearing: 90,
        speed: 1,
        curve: 1,
        easing: function (t) { return t },
      },
      onChapterEnter: [],
      onChapterExit: [],
    },
  ],
}

export default config
