import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './style.scss'
import config from './config.js'
import mapboxgl from 'mapbox-gl'
import scrollama from 'scrollama'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const layerTypes = {
  'fill': ['fill-opacity'],
  'line': ['line-opacity'],
  'circle': ['circle-opacity', 'circle-stroke-opacity'],
  'symbol': ['icon-opacity', 'text-opacity'],
  'raster': ['raster-opacity'],
  'fill-extrusion': ['fill-extrusion-opacity'],
}

const alignments = {
  'left': 'lefty',
  'center': 'centered',
  'right': 'righty',
}

const transformRequest = (url) => {
  const hasQuery = url.indexOf('?') !== -1
  const suffix = hasQuery ? '&pluginName=journalismScrollytelling' : '?pluginName=journalismScrollytelling'
  return {
    url: url + suffix,
  }
}

class App extends Component {
  constructor () {
    super()
    this.state = {
      currentChapter: config.chapters[0],
    }
    // this.setState = this.setState.bind(this);
  }

  componentDidMount () {
    console.log(config)

    const mapStart = config.chapters[0].location

    mapboxgl.accessToken = config.accessToken

    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: config.style,
      center: mapStart.center,
      zoom: mapStart.zoom,
      pitch: mapStart.pitch,
      bearing: mapStart.bearing,
      transformRequest: transformRequest,
      antialias: true,
    })

    const marker = new mapboxgl.Marker()
    if (config.showMarkers) {
      marker.setLngLat(mapStart.center).addTo(map)
    }

    function getLayerPaintType (layer) {
      var layerType = map.getLayer(layer).type
      return layerTypes[layerType]
    }

    function setLayerOpacity (layer) {
      var paintProps = getLayerPaintType(layer.layer)
      paintProps.forEach(function (prop) {
        map.setPaintProperty(layer.layer, prop, layer.opacity)
      })
    }

    var myReq

    function rotateCamera (timestamp) {
      // clamp the rotation between 0 -360 degrees
      // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
      // map.rotateTo((timestamp / 100) % 360, {duration: 0})
      map.setBearing(timestamp / 500)

      // Request the next frame of the animation.
      myReq = requestAnimationFrame(rotateCamera)
    }

    const setState = this.setState.bind(this)

    // instantiate the scrollama
    const scroller = scrollama()

    map.on('load', function () {
      // setup the instance, pass callback functions
      rotateCamera(50)

      var layers = map.getStyle().layers
 
      var labelLayerId
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id
          break
        }
      }
       
      map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',
       
          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate', ['linear'], ['zoom'],
            15, 0,
            15.05, ['get', 'height'],
          ],
          'fill-extrusion-base': [
            'interpolate', ['linear'], ['zoom'],
            15, 0,
            15.05, ['get', 'min_height'],
          ],
          'fill-extrusion-opacity': 0.6,
        },
      }, labelLayerId)

      scroller
        .setup({
          step: '.step',
          offset: 0.5,
          progress: true,
        })
        .onStepEnter(response => {
          const chapter = config.chapters.find(chap => chap.id === response.element.id)
          setState({currentChapter: chapter})
          map.flyTo(chapter.location)

          if (config.showMarkers) {
            marker.setLngLat(chapter.location.center)
          }
          if (chapter.onChapterEnter.length > 0) {
            chapter.onChapterEnter.forEach(setLayerOpacity)
          }
        })
        .onStepExit(response => {
          cancelAnimationFrame(myReq)
          var chapter = config.chapters.find(chap => chap.id === response.element.id)
          if (chapter.onChapterExit.length > 0) {
            chapter.onChapterExit.forEach(setLayerOpacity)
          }
        })
    })

    window.addEventListener('resize', scroller.resize)
  }

  render () {
    const theme = config.theme
    const currentChapterID = this.state.currentChapter.id

    const totalStoryCount = config.chapters.length

    return (
      <div>
        <div ref={el => this.mapContainer = el} className='absolute top right left bottom' />
        <div id='story'>
          {config.title &&
          <div id='header' className={theme}>
            <h1 className={`title`}>{config.title}</h1>
            {config.subtitle &&
            <h2 className={`subtitle`}>{config.subtitle}</h2>
            }
            {config.byline &&
            <p>{config.byline}</p>
            }
          </div>
          }
          <div id='features' className={alignments[config.alignment]}>
            {
              config.chapters.map((chapter, index) =>
                <Chapter key={chapter.id} totalStoryCount={totalStoryCount} index={index} theme={theme} {...chapter} currentChapterID={currentChapterID} />
              )

            }
          </div>
          {config.footer &&
          <div id='footer' className={theme}>
            <p>{config.footer}</p>
          </div>
          }
        </div>
      </div>
    )
  }
}

function Chapter ({totalStoryCount, id, index, theme, title, subtitle, byline, image, iframe, description, currentChapterID}) {
  const classList = id === currentChapterID ? 'step active' : 'step'
  
  var prevChapter = 'step-' + Number(index)
  var currentChapter = 'step-' + Number(index + 1)
  var nextChapter = 'step-' + Number(index + 2)

  var first = false
  var last = false
  const current = id === currentChapterID ? true : false

  if (Number(index + 1) === 1) {
    first = true
  }

  if (Number(index + 1) === totalStoryCount) {
    last = true
  }

  console.log(first + ' ' + last)

  return (
    <div id={id} className={classList}>
      <div className={`${theme} ${index} ${totalStoryCount}`}>
        { title &&
        <h1 className='title'>{title}</h1>
        }
        { subtitle &&
        <h1 className='subtitle'>{subtitle}</h1>
        }
        { byline &&
        <h1 className='byline'>{byline}</h1>
        }
        { image &&
        <img src={image} alt={title} />
        }
        { iframe &&
        <iframe width='100%' height='300px' src={iframe} alt='matterport Virtual Tour' allowFullScreen='true' />
        }
        { description &&
        <p className={`description`}>{description}</p>
        }
        <AnchorLink
          className={
            `button is-primary`
          }
          href={'#' + prevChapter}
          offset={84}
          disabled={first ? 'disabled' : ''}
        >
          Previous
        </AnchorLink>

        <AnchorLink
          className={
            `button is-primary`
          }
          href={'#' + nextChapter}
          offset={84}
          disabled={last ? 'disabled' : ''}
        >
          Next
        </AnchorLink>

      </div>
    </div>
  )
}

export default App

